var keywordsFormMustEl = document.querySelector('#id_must_ids');
var keywordsFormNiceEl = document.querySelector('#id_nice_ids');
var keywordsEl = document.querySelector('.js-jobentry-keywords__tags');

// Escape string for use in HTML
// https://stackoverflow.com/a/66481918
function escapeHTML(unsafe) {
  return ('' + unsafe).replace(
    // eslint-disable-next-line no-control-regex
    /[\u0000-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u00FF]/g,
    c => '&#' + ('000' + c.charCodeAt(0)).substr(-4, 4) + ';'
  )
}

function sortKeywords(a, b) {
  if (a.must != b.must) {
    if (a.must) return -1;
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
}

function addKeyword(kw) {
  if (window.jobentry_keywords.length >= 10) return;
  if (window.jobentry_keywords.some(function(c) { return c.id == kw.id })) return;
  window.jobentry_keywords.push({ name: kw.name, id: kw.id, must: false });
  window.jobentry_keywords.sort(sortKeywords);
  createKeywordElements(window.jobentry_keywords);
  setFormKeywords(window.jobentry_keywords);
  clearKeywordsInput();
  addKeywordToDataLayer(kw);
}
window.addKeyword = addKeyword

function removeKeyword(kw) {
  window.jobentry_keywords = window.jobentry_keywords.filter(function(x) { return x.id != kw });
  createKeywordElements(window.jobentry_keywords);
  setFormKeywords(window.jobentry_keywords);
}

function toggleKeywordMust(kw) {
  window.jobentry_keywords = window.jobentry_keywords.map(function(c) {
    if (c.id == kw) c.must = !c.must;
    return c;
  });
  window.jobentry_keywords.sort(sortKeywords);
  createKeywordElements(window.jobentry_keywords);
  setFormKeywords(window.jobentry_keywords);
}

function createKeywordElements(kws) {
  var editable = window.jobentry_keywords_editable;

  if (keywordsEl) {
    if (!kws.length) {
      keywordsEl.style.display = 'none';
      keywordsEl.innerHTML = '';
      return;
    }
    var html = kws.reduce(function(a, c) { return a + createKeywordElement(c, editable); }, '');
    keywordsEl.innerHTML = html;
    keywordsEl.style.display = 'inline-block';
  }
}

function setFormKeywords(kws) {
  if (keywordsFormNiceEl && keywordsFormMustEl) {
    var musts = [], nices = [];
    kws.forEach(function(kw){
      if (kw.must){
        musts.push(kw.id)
      } else {
        nices.push(kw.id)
      }
    });
    keywordsFormNiceEl.value = nices.join(',')
    keywordsFormMustEl.value = musts.join(',')
  }
}

function createKeywordElement(kw, editable) {
  var el = '<button type="button" data-kw="' + escapeHTML(kw.id) + '" class="btn jobentry-keywords__tag';
  el += editable ? ' js-jobentry-keywords__tag' : '';
  el += kw.must ? ' jobentry-keywords__tag__must' : ' jobentry-keywords__tag__nice';
  el += '"><span class="jobentry-keywords__tag__icon';
  el += editable ? ' js-jobentry-keywords__tag__icon' : '';
  el += '"></span>' + escapeHTML(kw.name);
  el += editable ? '<span class="jobentry-keywords__tag__remove js-jobentry-keywords__tag__remove"></span>' : '';
  el += '</button>';
  return el;
}

function clearKeywordsInput() {
  document.querySelector('#id_keywords').value = '';
}

function addKeywordToDataLayer(kw) {
  window.dataLayer.push({
    event: 'addKeywordToJobentry',
    'jobentryKeyword': kw.name
  });
}


(function initKeywords() {
  if (!keywordsEl) {
    return;
  }

  // Bind listener
  document.addEventListener('click', function(e) {
    if (e.target.classList.contains('js-jobentry-keywords__tag')) toggleKeywordMust(e.target.dataset.kw);
    if (e.target.classList.contains('js-jobentry-keywords__tag__icon')) toggleKeywordMust(e.target.parentElement.dataset.kw);
    if (e.target.classList.contains('js-jobentry-keywords__tag__remove')) removeKeyword(e.target.parentElement.dataset.kw);
  });

  // Init
  if (window.jobentry_keywords.length) {
    window.jobentry_keywords.sort(sortKeywords);
    createKeywordElements(window.jobentry_keywords);
    setFormKeywords(window.jobentry_keywords);
  }
}());
